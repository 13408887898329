<template>
  <v-app>
    <v-container
      id="create-pwd-container"
      fluid
      tag="section"
      class="fill-height"
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          lg="8"
        >
          <v-card
            elevation="24"
            class="px-4 py-7"
          >
            <v-card-title>
              Création de votre compte TrainyFlow
            </v-card-title>
            <v-form
              @submit.prevent="submit"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        outlined
                        label="Identifiant (e-mail)"
                        class="purple-input"
                        type="email"
                        v-model="demande.email"
                        disabled
                      />
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        outlined
                        v-model="newPassword"
                        label="Mot de passe"
                        :type="showpass ? 'text' : 'password'"
                        required
                        @input="checkPasswordRules()"
                      >
                        <template v-slot:append>
                          <v-icon
                            v-if="!showpass"
                            class="cursor-pointer"
                            @click="showpass = true"
                          >
                              mdi-eye
                          </v-icon>
                          <v-icon
                            v-if="showpass"
                            class="cursor-pointer"
                            @click="showpass = false"
                          >
                              mdi-eye-off
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        outlined
                        v-model="newPasswordConfirm"
                        label="Confirmer le mot de passe"
                        :type="showpassconfirm ? 'text' : 'password'"
                        required
                        @input="checkPasswordsSame()"
                      >
                        <template v-slot:append>
                          <v-icon
                            v-if="!showpassconfirm"
                            class="cursor-pointer"
                            @click="showpassconfirm = true"
                          >
                              mdi-eye
                          </v-icon>
                          <v-icon
                            v-if="showpassconfirm"
                            class="cursor-pointer"
                            @click="showpassconfirm = false"
                          >
                              mdi-eye-off
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex align-center"
                >
                  <ul class="instructions-password">
                    <li :class="check8car">Le mot de passe doit contenir au moins 8 caractères</li>
                    <li :class="checkMin">Le mot de passe doit contenir au moins une minuscule</li>
                    <li :class="checkMaj">Le mot de passe doit contenir au moins une majuscule</li>
                    <li :class="checkNb">Le mot de passe doit contenir au moins un nombre</li>
                    <li :class="checkSpec">Le mot de passe doit contenir au moins un caractère spécial</li>
                    <li :class="checkSame">Les mots de passe doivent être identiques</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row
                justify="center"
              >
                <v-col
                  cols="10"
                >
                  <v-btn
                    type="submit"
                    color="primary"
                    block
                    large
                    :disabled="[newPassword, newPasswordConfirm].some((e) => e == '') || !checkPasswordRules() || !checkPasswordsSame()"
                  >
                    Créer le compte
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <dashboard-core-alert-snackbar />
  </v-app>
</template>

<script>

  import { mapMutations } from 'vuex';

  export default {
    name: 'Login',

    components: {
      DashboardCoreAlertSnackbar: () => import('../dashboard/components/core/AlertSnackbar'),
    },

    data() {
      return {
        token: '',
        demande: {},
        sendDisabled: false,
        newPassword: '',
        newPasswordConfirm: '',
        showpass: false,
        showpassconfirm: false,
        check8car: '',
        checkMin: '',
        checkMaj: '',
        checkNb: '',
        checkSpec: '',
        checkSame: '',
      }
    },

    created() {
      this.token = this.$route.query.token;
      this.axios.get('/demandesInscription/createUser/findDemande?token=' + this.token)
        .then((res) => {
          this.demande = { ...res.data };
        })
        .catch((e) => {
          this.setSnack({ text: e, type: 'warn' });
          this.$router.push({ name: 'Login' });
        });
    },

    methods: {
      submit() {
        this.sendDisabled = true;
        this.axios.post('/demandesInscription/createUser', { token: this.token, password: this.newPassword })
          .then((res) => {
            this.setSnack({ text: 'Le compte utilisateur a bien été créé', type: 'success' });
            this.$router.push({ name: 'Login' });
          })
          .catch((e) => {
            this.setSnack({ text: e, type: 'warn' });
            this.sendDisabled = false;
          });
      },

      checkPasswordRules() {
        let passwordOk = false;

        // 8 caractères ou plus
        if (new RegExp('(?=.{8,})').test(this.newPassword)) {
          this.check8car = 'correct';
          passwordOk = true;
        } else {
          this.check8car = '';
          passwordOk = false;
        }

        // Minuscle
        if (new RegExp('(?=.*[a-z])').test(this.newPassword)) {
          this.checkMin = 'correct';
          passwordOk = true;
        } else {
          this.checkMin = '';
          passwordOk = false;
        }

        // Majuscle
        if (new RegExp('(?=.*[A-Z])').test(this.newPassword)) {
          this.checkMaj = 'correct';
          passwordOk = true;
        } else {
          this.checkMaj = '';
          passwordOk = false;
        }

        // Nombre
        if (new RegExp('(?=.*[0-9])').test(this.newPassword)) {
          this.checkNb = 'correct';
          passwordOk = true;
        } else {
          this.checkNb = '';
          passwordOk = false;
        }

        // Caractère spécial
        if (new RegExp('(?=.*[^A-Za-z0-9])').test(this.newPassword)) {
          this.checkSpec = 'correct';
          passwordOk = true;
        } else {
          this.checkSpec = '';
          passwordOk = false;
        }

        this.checkPasswordsSame();

        return passwordOk;
      },

      checkPasswordsSame() {
        let passwordOk = false;

        if (this.newPassword !== '' && this.newPassword === this.newPasswordConfirm) {
          this.checkSame = 'correct';
          passwordOk = true;
        } else {
          this.checkSame = '';
          passwordOk = false;
        }

        return passwordOk;
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>

<style scoped>
  #create-pwd-container {
    min-height: 100vh;
    background-image: url('../../assets/wallpaper-login.jpg');
    background-size: cover;
    background-position: center;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  ul.instructions-password li {
    font-size: 0.85em;
    color: #b2b2b2;
    list-style-type: '\2715';
    margin: 6px 0;
    padding-left: 6px;
  }

  ul.instructions-password li.correct {
    list-style-type: '\2713';
    color: #4caf50;
  }

</style>
